import { Button, Input, Select, Table } from "antd"
import * as React from "react"
import PageLayout from "../../components/pageLayout"
import firebase from "firebase"
import { Link } from "gatsby"
import { firebaseInit } from "../../firebaseConfig"


const {Column} = Table
const {Option} = Select 

const ImportCollectionPage = () => {

const [url, setUrl] = React.useState();
const [crawlSessions, setCrawlSessions] = React.useState([]);


React.useEffect(()=>{
  firebaseInit(firebase)

  firebase.firestore().collection("crawlRegistry").get().then(queryResult => {

      let sessions = []
      queryResult.forEach(doc =>  {

          let session = doc.data();
          sessions.push(session)
      })
      console.log(sessions)
      setCrawlSessions(sessions)
    
  })
}, [])

const onImportClick =  () => {

  let body = {
    targetUrl : url,
    targetType : "collection"  
  }                    
  fetch("https://us-central1-price-shoes-connect.cloudfunctions.net/crawl", { 
         body:JSON.stringify(body), 
                  method:'post',
                  headers: new Headers({                 
                  'Accept': 'application/json'})})
        .then(response => {

          if(response.ok)
          {
              response.json().then(data=> {                
                setCrawlSessions([...crawlSessions, data]);
              })      
          }
        })
        .catch(err => {console.log(err)});  
}

  return (

    <PageLayout>
        <main> 
          <label>Página de colección</label>
          <Input placeholder="https://www.priceshoes.com/collection-page" value={url} onChange={(e)=>{
            setUrl(e.target.value)}}></Input>
          <Button type="primary" onClick={onImportClick}>Importar</Button>
        </main>

      

       <Table dataSource={crawlSessions} rowKey="id">
          <Column title=""></Column>
          <Column title="Session ID" dataIndex="id" render={value=><Link to={`/import/${value}`}>{value}</Link>}></Column>
          <Column title="Url" dataIndex="target"></Column>
          <Column title="Descripción"></Column>
          <Column></Column>
       </Table>
    </PageLayout>
  )
}

export default ImportCollectionPage
